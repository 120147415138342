import React from 'react';
import { GraduationCap, Award, Brain, Clock, Calendar } from 'lucide-react';

const CreativeWrapped = () => {
  return (
    <div className="min-h-screen  items-center bg-gradient-to-br from-slate-950 via-purple-950 to-fuchsia-950 p-8">
      <div className="max-w-4xl mx-auto space-y-32 py-20">
        {/* Hoş Geldin Bölümü */}
        <div className="flex items-center gap-12">
          <div className="bg-white/5 backdrop-blur-lg p-4 rounded-full">
            <GraduationCap className="w-12 h-12 text-emerald-400" />
          </div>
          <div className="flex-1">
            <h2 className="text-5xl font-black italic bg-gradient-to-r from-emerald-400 via-teal-300 to-cyan-300 bg-clip-text text-transparent mb-6 tracking-tight">
              Hoş Geldin!
            </h2>
            <p className="text-xl font-light leading-relaxed">
              <span className="text-emerald-300 font-semibold">Sevgili Buse,</span>
              <span className="text-teal-200"> 2024 Ev Okulu App ile senin için </span>
              <span className="text-cyan-300 font-bold">eğitim dolu bir yıldı.</span>
              <span className="text-emerald-200"> Hadi birlikte geride bıraktığımız seneye göz atalım!</span>
            </p>
          </div>
        </div>

        {/* Sertifika Bölümü */}
        <div className="flex items-center gap-12 flex-row-reverse">
          <div className="bg-white/5 backdrop-blur-lg p-4 rounded-full">
            <Award className="w-12 h-12 text-rose-400" />
          </div>
          <div className="flex-1 text-right">
            <h2 className="text-5xl font-black bg-gradient-to-r from-rose-400 via-pink-300 to-fuchsia-300 bg-clip-text text-transparent mb-6 tracking-tight">
              17 Sertifika!
            </h2>
            <p className="text-xl font-light leading-relaxed">
              <span className="text-rose-300">Kulağa inanması güç geliyor ama </span>
              <span className="text-pink-300 font-bold">bu sene tam 17 sertifikalı eğitim tamamlayarak </span>
              <span className="text-fuchsia-200">kariyerinde bir adım öne çıktın.</span>
            </p>
          </div>
        </div>

        {/* Azim Bölümü */}
        <div className="flex items-center gap-12">
          <div className="bg-white/5 backdrop-blur-lg p-4 rounded-full">
            <Brain className="w-12 h-12 text-blue-400" />
          </div>
          <div className="flex-1">
            <h2 className="text-5xl font-black bg-gradient-to-r from-blue-400 via-indigo-300 to-violet-300 bg-clip-text text-transparent mb-6 tracking-tight transform -rotate-1">
              Azmin Zaferi
            </h2>
            <p className="text-xl font-light leading-relaxed">
              <span className="text-blue-300">Biliyoruz, </span>
              <span className="text-indigo-300 font-bold">sınavlar bazen zor olabilir. </span>
              <span className="text-violet-200">Bu sene seni en çok yoran eğitim </span>
              <span className="text-blue-200 font-semibold">Kısa Süreli Çözüm Odakli Terapi Eğitimi oldu. </span>
              <span className="text-indigo-200">Tam 4 kere sınavına girdin</span>
            </p>
          </div>
        </div>

        {/* En Çok İzlenen */}
        <div className="flex items-center gap-12 flex-row-reverse">
          <div className="bg-white/5 backdrop-blur-lg p-4 rounded-full">
            <Clock className="w-12 h-12 text-violet-400" />
          </div>
          <div className="flex-1 text-right">
            <h2 className="text-5xl font-black bg-gradient-to-r from-violet-400 via-purple-300 to-fuchsia-300 bg-clip-text text-transparent mb-6 tracking-tight transform rotate-1">
              En Çok İzlenen
            </h2>
            <p className="text-xl font-light leading-relaxed">
              <span className="text-violet-300">Dinamik Psikoterapi Temel Eğitim Programina </span>
              <span className="text-purple-200 font-bold">o kadar ilgi gösterdin ki </span>
              <span className="text-fuchsia-300">tam 82 saatini </span>
              <span className="text-violet-200">bu eğitimin derslerini izleyerek geçirdin.</span>
            </p>
          </div>
        </div>

        {/* Yıl Özeti */}
        <div className="flex items-center gap-12">
          <div className="bg-white/5 backdrop-blur-lg p-4 rounded-full">
            <Calendar className="w-12 h-12 text-teal-400" />
          </div>
          <div className="flex-1">
            <h2 className="text-5xl font-black bg-gradient-to-r from-teal-400 via-emerald-300 to-green-300 bg-clip-text text-transparent mb-6 tracking-tight transform -rotate-1">
              Yıl Özeti
            </h2>
            <p className="text-xl font-light leading-relaxed">
              <span className="text-teal-300">2024'te tam </span>
              <span className="text-emerald-300 font-bold">15 gününü </span>
              <span className="text-green-200">aralıksız eğitim alarak geçirdin. </span>
              <span className="text-teal-200 font-semibold">(28728 dk)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeWrapped;