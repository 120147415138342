import React from "react";

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-pink-50 to-blue-50 text-gray-900">
      <header className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-400 text-white py-8 shadow-lg">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-3xl md:text-4xl font-bold tracking-tight">
            ÖĞRETMENLER Günü / Haftası HEDİYELERİ
          </h1>
        </div>
      </header>

      <main className="container mx-auto py-12 px-4 max-w-6xl">
        <section className="bg-white p-8 rounded-xl shadow-xl">
          <h2 className="text-2xl font-bold mb-6 text-purple-600">
            Değerli Eğitim Dostu 💐
          </h2>
          
          <div className="space-y-6 text-gray-700">
            <p className="text-lg">
              24 Kasım Öğretmenler Günü vesilesiyle, Türkiye'nin önde gelen eğitim STK'sı olarak öğretmenlerimize hediyemiz var🎁
              <br />
              <span className="font-semibold text-pink-600">24 Kasım – 31 Aralık 2024 </span> tarihleri arasında;
            </p>

            <p className="text-lg font-medium">
              Yaklaşık 60 civarında sertifikalı ücretsiz eğitim programının da yer aldığı{" "}
              <span className="font-bold text-orange-500">EV OKULU APP</span> mobil uygulamasında:
            </p>

            <div className="bg-gradient-to-r from-purple-50 to-pink-50 p-6 rounded-lg my-6">
              <p className="text-lg">
                24 Değerli Alan Uzmanının yer aldığı <span className="font-bold">PSİKOLOJİ ZİRVESİ</span> % 48 indirimle sunulmaktadır.
              </p>
              <p className="text-lg mt-4">
                Ev Okulu APP mobil uygulamasında <span className="font-semibold">PSİKOLOJİ</span> kodunu eğitim satın almada girerek <span className="line-through text-gray-500">489 TL</span> yerine <span className="font-bold text-green-600">255 TL</span>'ye kayıt olabilirsiniz.
              </p>
              <div className="mt-6 flex justify-center">
                <img 
                  src="/online_zirve_psikoloji.jpeg" 
                  alt="Psikoloji Zirvesi" 
                  className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 max-w-xl h-auto object-contain"
                />
              </div>
            </div>
              <p className="text-xl font-semibold text-purple-600">AYRICA</p>
              


            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 my-8">
              {[
                "1. Vaka Odaklı İleri Düzey Aile Danışmanlığı",
                "2. Enneagram",
                "3. Yaratıcı Drama İle Akran Zorbalığı",
                "4. Temel İşaret Dili",
                "5. Temel Diksiyon",
                "6. Montessori",
                "7. Psikolojik Danışmanlar İçin Eğitimde Drama Teknikleri",
                "8. Çocuklar İçin Felsefe"
              ].map((item, index) => (
                <li key={index} className="flex items-center bg-gradient-to-r from-purple-50 to-pink-50 p-4 rounded-lg hover:from-purple-100 hover:to-pink-100 transition-all duration-300">
                  <span className="font-medium">{item}</span>
                </li>
              ))}
            </ul>

            <section className="my-12">
              <div className="aspect-video">
                <img src="/gift.jpeg" alt="Örnek Görsel 1" className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain" />
              </div>
              <div className="aspect-video">
                <img src="/gift1.jpeg" alt="Örnek Görsel 2" className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain" />
              </div>
              <div className="aspect-video">
                <img src="/gift7.jpeg" alt="Örnek Görsel 3" className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain" />
              </div>
            </section>

            <p className="text-lg font-medium">
              Eğitim programlarını <span className="font-bold text-purple-600">E-Devlet Onaylı Sertifika</span> dahil hediye etmekten memnuniyet duyacağız.
            </p>

            <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-lg space-y-6">
              <h3 className="text-xl font-bold text-gray-900">❗Gerekli Adımlar!</h3>
              <ol className="space-y-4">
                {[
                  "Ev Okulu App'i PlayStore/App Store'dan indirerek kaydolmak",
                  "Eğitimlerde Eğitime Kaydol diyerek koda ÖĞRETMENLERGÜNÜ yazıp kaydolmak",
                  "Eğitimler/Ücretsiz bölümdeki yaklaşık 60 programa kaydolmak"
                ].map((step, index) => (
                  <li key={index} className="flex gap-4">
                    <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full font-bold">
                      {index + 1}
                    </span>
                    <span className="text-gray-700">{step}</span>
                  </li>
                ))}
              </ol>
            </div>

            <div className="bg-gradient-to-r from-yellow-50 to-orange-50 p-6 rounded-lg border-l-4 border-yellow-400 space-y-4">
              <p className="text-gray-700">
                <span className="font-bold">Not:</span> Ev Okulu App uygulamasında herhangi bir abonelikle size hediye edilen 8 Eğitim Programı için sertifika dahil ek bir ödeme alınmamaktadır. Yeni hediye programlar ilan edilecektir.
              </p>
              <p className="text-gray-700">
                <span className="font-bold">Not:</span>Yukarıda Öğretmenler Günü / Haftası dolayısıyla hediye edilen eğitim programlarından ücret ödeyerek satın almış katılımcılarımızın uygulamada DESTEK bölümünden bize ulaşmaları durumunda ödedikleri ücret kadar uygulamada kullanabilecekleri Para Puan hesaplarına yansıtılacaktır.
              </p>
              <p className="text-gray-700">
                <span className="font-bold">Not:</span> Tahminen 15 Aralık itibariyle çıkaracağımız güncellemeden sonra da programlara kayıt olurken PARA PUANIMI KULLAN butonu aktif olacaktır.
              </p>

            </div>

            <div className="space-y-4 mt-8">
              {[
                {
                  href: "https://www.youtube.com/shorts/mGG4L4DN0Iw",
                  text: "Eğitim %100 indirim kodu girme ÖRNEK VİDEOSU"
                },
                {
                  href: "https://www.youtube.com/watch?v=E2RI8xEmqeE&feature=youtu.be",
                  text: "Ev Okulu APP Kullanımı Hakkında Video"
                }
              ].map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-purple-600 hover:text-pink-600 transition-colors duration-200"
                >
                  <span className="w-6 h-6 flex-shrink-0 bg-gradient-to-r from-purple-100 to-pink-100 rounded-full flex items-center justify-center">
                    ▶
                  </span>
                  {link.text}
                </a>
              ))}
            </div>
          </div>
        </section>

        <div className="mt-12 flex flex-col sm:flex-row items-center justify-center gap-6">
          <a
            href="https://play.google.com/store/apps/details?id=org.evokulu.app&hl=tr"
            target="_blank"
            rel="noopener noreferrer"
            className="w-48 h-16 bg-black text-white rounded-xl flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors duration-200"
          >
            <svg className="w-8 h-8" viewBox="0 0 512 512" fill="currentColor">
              <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/>
            </svg>
            <div className="text-left">
              <div className="text-xs">GET IT ON</div>
              <div className="text-xl font-medium">Google Play</div>
            </div>
          </a>
          
          <a
            href="https://apps.apple.com/tr/app/ev-okulu-app/id6478512351?l=tr"
            target="_blank"
            rel="noopener noreferrer"
            className="w-48 h-16 bg-black text-white rounded-xl flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors duration-200"
          >
            <svg className="w-8 h-8" viewBox="0 0 384 512" fill="currentColor">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
            </svg>
            <div className="text-left">
              <div className="text-xs">Download on the</div>
              <div className="text-xl font-medium">App Store</div>
            </div>
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;