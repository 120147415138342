import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from 'reactjs-popup';
import EducationCard from "../components/Education/EducationCard";
import ExpertCard from "../components/Education/experts/ExpertCard";
import educationData from "../components/Education/Data";
import expertData from "../components/Education/experts/Data";
import alluserData from "../components/Education/alluser/Data";
import AllCard from "../components/Education/alluser/AllCard";
import Data from "./Edudata";
import EduBanner from "../components/EduBanner";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

const Educations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const categoryFromURL = searchParams.get("category");

  const categories = [
    { name: "Uzmanlara Yönelik Eğitimler", key: "uzmanlar" },
    { name: "Herkese Yönelik Eğitimler", key: "herkes" },
    { name: "Çocukların Gelişimine Yönelik Eğitimler", key: "cocuklar" }
  ];

  const [selectedCategory, setSelectedCategory] = useState(categoryFromURL || categories[0].key);
  const [initialLoad, setInitialLoad] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    if (!categoryFromURL && initialLoad) {
      setInitialLoad(false);
      navigate(`?category=${selectedCategory}`);
    }
  }, [categoryFromURL, initialLoad, selectedCategory, navigate]);

  const handleCategoryChange = (categoryKey) => {
    setSelectedCategory(categoryKey);
    navigate(`?category=${categoryKey}`);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    const modalContent = document.getElementById("popup-content");
    if (modalContent) {
      modalContent.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("Element with ID 'popup-content' not found.");
    }
  };

  const customOrders = {
    uzmanlar: [14, 0, 13, 10, 2, 7, 8, 1, 12, 5, 6, 9],
    herkes: [0, 27, 31, 32, 33, 23, 22, 25, 21],
    cocuklar: [20, 11, 14]
  };

  const sortByCustomOrder = (a, b, customOrder) => {
    if (!customOrder.includes(a.id) && !customOrder.includes(b.id)) {
      return a.id - b.id;
    }

    if (!customOrder.includes(a.id)) return 1;

    if (!customOrder.includes(b.id)) return -1;

    return customOrder.indexOf(a.id) - customOrder.indexOf(b.id);
  };

  let filteredData = [];
  if (selectedCategory === "uzmanlar") {
    filteredData = expertData.slice();
    filteredData.sort((a, b) => sortByCustomOrder(a, b, customOrders.uzmanlar));
  } else if (selectedCategory === "herkes") {
    filteredData = alluserData.slice();
    filteredData.sort((a, b) => sortByCustomOrder(a, b, customOrders.herkes));
  } else if (selectedCategory === "cocuklar") {
    filteredData = educationData.slice();
    filteredData.sort((a, b) => sortByCustomOrder(a, b, customOrders.cocuklar));
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Box display="flex" justifyContent="center" mb={4}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/E2RI8xEmqeE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Education Video"
          ></iframe>
        </Box>

        {/* Adding the image below the video */}
        <Box display="flex" justifyContent="center" mb={4}>
          <img
            src="/bilgiyarismasi.jpeg"
            alt="Öğretmenler Günü Banner"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>

        <Box display="flex" justifyContent="center" mb={4}>
          <Button
            variant="contained"
            color="primary"
            href="https://docs.google.com/forms/d/e/1FAIpQLSd_KsAIX4-Oyii2UdsZ-7z7PD4px1Z6FJQg4nIUK7WC1sYuHw/viewform"
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: '12px 24px', fontWeight: 'bold' }}
          >
            Avantajlı Kayıt Koşullarıyla Eğitim Başvurusu için Tıklayınız
          </Button>
        </Box>

        <Card variant="outlined" style={{ marginBottom: '24px' }}>
          <CardContent>
            <Typography variant="h6" component="h1">
              Aşağıdaki Eğitimler Ev Okulu APP'te ÜCRETSİZ Katılabileceğiniz Eğitim Listesidir (Lütfen Açıklamayı Okuyunuz)
            </Typography>
            <Typography variant="body2" component="p">
              Siz değerli arkadaşlarımız daha önce Ev Okulu Derneği’nin herhangi bir çalışmasında, eğitim programında veya hizmetinde bulunmuş dostlarımızsınız. 2,5 seneyi aşkın üzerinde büyük bir emek ve hassasiyetle çalıştığımız Ev Okulu Aplikasyonu hizmete girmiştir.
              <Button onClick={openPopup} color="primary">Devamı için tıklayın</Button>
            </Typography>
          </CardContent>
        </Card>

        {/* <EduBanner /> */}

        <Box display="flex" justifyContent="center" mb={4} mt={4}>
          {categories.map((category) => (
            <Button
              key={category.key}
              variant="contained"
              color={selectedCategory === category.key ? "secondary" : "primary"}
              onClick={() => handleCategoryChange(category.key)}
              style={{ marginRight: '8px', padding: '12px 24px', fontWeight: 'bold' }}
            >
              {category.name}
            </Button>
          ))}
        </Box>

        <Grid container spacing={4}>
          {selectedCategory === "uzmanlar" && filteredData.map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
              <ExpertCard expert={item} />
            </Grid>
          ))}
          {selectedCategory === "herkes" && filteredData.map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
              <AllCard all={item} />
            </Grid>
          ))}
          {selectedCategory === "cocuklar" && filteredData.map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
              <EducationCard education={item} />
            </Grid>
          ))}
        </Grid>

        <Popup open={popupOpen} onClose={closePopup}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
            <Box position="relative" width="100%" maxWidth="600px" bgcolor="background.paper" p={4} borderRadius="8px" boxShadow={3}>
              <Button onClick={closePopup} style={{ position: 'absolute', top: 8, right: 8 }}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </Button>
              <Box id="popup-content" maxHeight="80vh" overflow="auto">
                <Typography variant="h6" component="p" gutterBottom>
                  {Data[0].subtitle}
                </Typography>
                <Typography variant="body1">
                  {Data[0].content}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Popup>
      </Box>
    </Container>
  );
};

export default Educations;
