import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import About from "./pages/About"
import ContactPage from "./pages/ContactPage"
import Notfound from "./pages/Notfound"
import Kvkk from "./pages/contract/Kvkk"
import Mobile from "./pages/contract/Mobile"
import Onem from "./pages/contract/Onem"
import Oney from "./pages/contract/Oney"
import Sixm from "./pages/contract/Sixm"
import Appin from "./pages/Appin"
import WhyApp from "./pages/WhyApp"
import Deleteacc from "./pages/Deleteacc"
import Faq from "./pages/Faq"
import EducationDetail from "./components/Education/EducationDetail"
import Educations from "./pages/Educations"
import ExpertDetail from "./components/Education/experts/ExpertDetail"
import AllDetail from "./components/Education/alluser/AllDetail"
import Hizli from "./pages/Hizli" 
import News from "./pages/News"
import Secret from "./pages/Secret"
import Gift from "./pages/Gift"
import Akansel from "./pages/Akansel"
import Wrapped from "./pages/Wrapped"


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
      <Navbar />
      <div className="mt-24"> 
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/bizi-taniyin" element={<About />} />
              <Route path="/bize-ulasin" element={<ContactPage />} />
              <Route path="/ev_okulu_appte_ne_var/" element={<Appin />} />
              <Route path="/ev_okulu_ap_ne_ise_yarar/" element={<WhyApp />} />
              <Route path="*" element={<Notfound />} />
              <Route path="/kvkk" element={<Kvkk />} />
              <Route path="/kullanici-sozlesmesi" element={<Mobile />} />
              <Route path="/biraylik_abonelik_sozlesme" element={<Onem />} />
              <Route path="/biryillik_abonelik_sozlesme" element={<Oney />} />
              <Route path="/altiaylik_abonelik_sozlesme" element={<Sixm />} /> 
              <Route path="/hesap-silme" element={<Deleteacc />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/abonelikfirsatlari/cocuklara_yonelik/:id" element={<EducationDetail />} />
              <Route path="/abonelikfirsatlari" element={<Educations />} />
              <Route path="/abonelikfirsatlari/uzmanlara_yonelik/:id" element={<ExpertDetail />} />
              <Route path="/abonelikfirsatlari/herkese_yonelik/:id" element={<AllDetail />} />
              <Route path="/odevimi-nasil-gonderirim" element={<Hizli />} /> 
              <Route path="/neler-yeni" element={<News />} /> 
              <Route path="/ogretmenlerhediyesi" element={<Secret />} /> 
              <Route path="/ogretmenlerhaftasihediyesi" element={<Gift />} /> 
              <Route path="/akanselhediyesi" element={<Akansel />} /> 
              <Route path="/batuhan" element={<Wrapped />} /> 


            </Routes>
          </Router>
      </div>
      <Footer />
    </>
  )
}

export default App