import React from "react";

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-pink-50 to-blue-50 text-gray-900">
      <header className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-400 text-white py-8 shadow-lg">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-3xl md:text-4xl font-bold tracking-tight">
            AKANSEL EĞİTİM DANIŞMANLIK’TAN EĞİTİM HEDİYELERİ
          </h1>
        </div>
      </header>

      <main className="container mx-auto py-12 px-4 max-w-6xl">
        <section className="bg-white p-8 rounded-xl shadow-xl">
          <h2 className="text-2xl font-bold mb-6 text-purple-600">
            Değerli Katılımcımız💐
          </h2>

          <div className="space-y-6 text-gray-700">
            <p className="text-lg">
              2024 yılının sonuna yaklaşırken, Akansel Eğitim Danışmanlık olarak
              sizler için hediyelerimiz var🎁
              <br />
              <span className="font-semibold text-pink-600">
                31 Aralık 2024 23:59
              </span>{" "}
              tarihine kadar;
            </p>

            <p className="text-lg font-medium">
              Yaklaşık 60 civarında sertifikalı ücretsiz eğitim programının da
              yer aldığı{" "}
              <span className="font-bold text-orange-500">EV OKULU APP</span>{" "}
              mobil uygulamasında:
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 my-8">
              {[
                "1. Vaka Odaklı İleri Düzey Aile Danışmanlığı",
                "2. Enneagram",
                "3. Yaratıcı Drama İle Akran Zorbalığı",
                "4. Temel İşaret Dili",
                "5. Temel Diksiyon",
                "6. Montessori",
                "7. Psikolojik Danışmanlar İçin Eğitimde Drama Teknikleri",
                "8. Çocuklar İçin Felsefe",
              ].map((item, index) => (
                <li
                  key={index}
                  className="flex items-center bg-gradient-to-r from-purple-50 to-pink-50 p-4 rounded-lg hover:from-purple-100 hover:to-pink-100 transition-all duration-300"
                >
                  <span className="font-medium">{item}</span>
                </li>
              ))}
            </ul>

            <section className="my-12">
              <div className="aspect-video">
                <img
                  src="/gift4.jpeg"
                  alt="Örnek Görsel 1"
                  className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain"
                />
              </div>
              <div className="aspect-video">
                <img
                  src="/gift5.jpeg"
                  alt="Örnek Görsel 2"
                  className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain"
                />
              </div>
              <div className="aspect-video">
                <img
                  src="/gift6.jpeg"
                  alt="Örnek Görsel 3"
                  className="rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 w-full h-full object-contain"
                />
              </div>
            </section>

            <p className="text-lg font-medium">
              Eğitim programlarını{" "}
              <span className="font-bold text-purple-600">
                E-Devlet Onaylı Sertifika
              </span>{" "}
              dahil hediye etmekten memnuniyet duyacağız.
            </p>

            <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-lg space-y-6">
              <h3 className="text-xl font-bold text-gray-900">
                ❗Gerekli Adımlar!
              </h3>
              <ol className="space-y-4">
                {[
                  "Ev Okulu App'i PlayStore/App Store'dan indirerek kaydolmak",
                  "Abonelik’te Satın Al butonu altında Abonelik Kodu’na AKANSEL30 yazarak kaydolmak",
                  "Eğitimlerde Eğitime Kaydol diyerek koda AKANSEL yazıp kaydolmak",
                  "Eğitimler/Ücretsiz bölümdeki yaklaşık 60 programa kaydolmak",
                ].map((step, index) => (
                  <li key={index} className="flex gap-4">
                    <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full font-bold">
                      {index + 1}
                    </span>
                    <span className="text-gray-700">{step}</span>
                  </li>
                ))}
              </ol>
            </div>

            <div className="bg-gradient-to-r from-yellow-50 to-orange-50 p-6 rounded-lg border-l-4 border-yellow-400 space-y-4">
              <p className="text-gray-700">
                <span className="font-bold">Not:</span> Ev Okulu App
                uygulamasında hediye abonelik sürecinden sonra herhangi bir
                abonelikle tüm programlara devam ve yeni programlara kayıt
                süreci devam edecektir. Sertifika için ek bir ödeme
                alınmamaktadır. Yeni hediye programlar ilan edilecektir.
              </p>
            </div>

            <div className="space-y-4 mt-8">
              {[
                {
                  href: "https://www.youtube.com/shorts/2UDUP9EeXkk",
                  text: "Abonelik kodu girme ÖRNEK VİDEOSU",
                },
                {
                  href: "https://www.youtube.com/shorts/mGG4L4DN0Iw",
                  text: "Eğitim %100 indirim kodu girme ÖRNEK VİDEOSU",
                },
                {
                  href:
                    "https://www.youtube.com/watch?v=E2RI8xEmqeE&feature=youtu.be",
                  text: "Ev Okulu APP Kullanımı Hakkında Video",
                },
              ].map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-purple-600 hover:text-pink-600 transition-colors duration-200"
                >
                  <span className="w-6 h-6 flex-shrink-0 bg-gradient-to-r from-purple-100 to-pink-100 rounded-full flex items-center justify-center">
                    ▶
                  </span>
                  {link.text}
                </a>
              ))}
            </div>
          </div>
        </section>

        <div className="mt-12 flex flex-col sm:flex-row items-center justify-center gap-6">
          <a
            href="https://play.google.com/store/apps/details?id=org.evokulu.app&hl=tr"
            target="_blank"
            rel="noopener noreferrer"
            className="w-48 h-16 bg-black text-white rounded-xl flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors duration-200"
          >
            <svg className="w-8 h-8" viewBox="0 0 512 512" fill="currentColor">
              <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
            </svg>
            <div className="text-left">
              <div className="text-xs">GET IT ON</div>
              <div className="text-xl font-medium">Google Play</div>
            </div>
          </a>

          <a
            href="https://apps.apple.com/tr/app/ev-okulu-app/id6478512351?l=tr"
            target="_blank"
            rel="noopener noreferrer"
            className="w-48 h-16 bg-black text-white rounded-xl flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors duration-200"
          >
            <svg className="w-8 h-8" viewBox="0 0 384 512" fill="currentColor">
              <path d="M318.7 268.7c-3-43.8 19.7-78.2 59.6-101-22-30.8-55.5-46.2-97-46.2-40.6 0-76.7 24.2-95.7 24.2-20.4 0-53.2-23.6-88.3-23.3C43.5 122.9 0 167.7 0 238c0 57 21.4 115.7 47.8 154.2 22.7 33.7 43.3 64.2 73.6 63.2 29.6-.9 40.8-19.2 76.2-19.4 35.5-.2 45.8 19.2 76 18.7 31.7-.5 51.5-31.7 74.2-65 12.7-19 21.9-38.5 28.5-54.2-64.3-24.5-61.7-89.7-57.6-110.8zM248.3 0C210.3 2.7 169 34.5 154.8 63.7c-24.8 50.4 6.5 126.3 44.4 125.2 34.8-.8 70.4-40 83.2-68.3 14-29 7.6-58.3-3.2-73.6C269.7 33.7 258.7 2 248.3 0z" />
            </svg>
            <div className="text-left">
              <div className="text-xs">Download on the</div>
              <div className="text-xl font-medium">App Store</div>
            </div>
          </a>
        </div>
      </main>

      <footer className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-400 text-white py-4">
        <div className="container mx-auto text-center">
          <p className="text-sm font-medium">
            &copy; {new Date().getFullYear()} AKANSEL EĞİTİM DANIŞMANLIK.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
